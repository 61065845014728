import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { AddressListItem, AddressType } from '@mp/organization-master-data/addresses/domain';

import { AddressesActions } from './addresses.actions';

export const addressesFeatureKey = 'mpomd-core-addresses';

type AddressesEntityState = EntityState<AddressListItem>;

export const addressesEntityAdapter = createEntityAdapter<AddressListItem>({
  selectId: ({ addressId }: AddressListItem) => addressId,
});

export interface AddressesState {
  addresses: AddressesEntityState;
  addressesLoaded: boolean;
  organizationHasDefaultBillingAddress: boolean;
  organizationHasDefaultDeliveryAddress: boolean;
  organizationHasMainAddress: boolean;
}

export const initialState: AddressesState = {
  addresses: addressesEntityAdapter.getInitialState(),
  addressesLoaded: true,
  organizationHasDefaultBillingAddress: false,
  organizationHasDefaultDeliveryAddress: false,
  organizationHasMainAddress: false,
};

export const addressesReducer = createReducer(
  initialState,
  on(
    AddressesActions.fetchAddresses,
    (state: AddressesState): AddressesState => ({
      ...state,
      addresses: addressesEntityAdapter.getInitialState(),
      addressesLoaded: false,
    }),
  ),
  on(
    AddressesActions.fetchAddressesSuccess,
    (state: AddressesState, { addressesPageResponse: { data } }): AddressesState => ({
      ...state,
      addresses: addressesEntityAdapter.setAll(data, state.addresses),
      addressesLoaded: true,
      organizationHasDefaultBillingAddress: organizationContainsAddressType(data, AddressType.DefaultBillingAddress),
      organizationHasDefaultDeliveryAddress: organizationContainsAddressType(data, AddressType.DefaultDeliveryAddress),
      organizationHasMainAddress: organizationContainsAddressType(data, AddressType.MainAddress),
    }),
  ),
  on(
    AddressesActions.fetchAddressesError,
    (state: AddressesState): AddressesState => ({
      ...state,
      addresses: addressesEntityAdapter.getInitialState(),
      addressesLoaded: true,
    }),
  ),
);

export const { selectAll: selectAllAddresses } = addressesEntityAdapter.getSelectors();

const organizationContainsAddressType = (addresses: AddressListItem[], type: AddressType): boolean => {
  return addresses.some((address) => address.addressTypes?.includes(type));
};
