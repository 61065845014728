import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';

import { AddressListItem } from '@mp/organization-master-data/addresses/domain';

import { AddressesActions } from './addresses.actions';
import {
  selectAddresses,
  selectAddressesLoaded,
  selectOrganizationHasDefaultBillingAddress,
  selectOrganizationHasDefaultDeliveryAddress,
  selectOrganizationHasMainAddress,
} from './addresses.selectors';

@Injectable()
export class AddressesFacade {
  readonly addresses: Signal<AddressListItem[]> = this.store$.selectSignal(selectAddresses);

  readonly addressesLoaded: Signal<boolean> = this.store$.selectSignal(selectAddressesLoaded);

  readonly organizationHasDefaultBillingAddress: Signal<boolean> = this.store$.selectSignal(
    selectOrganizationHasDefaultBillingAddress,
  );
  readonly organizationHasDefaultDeliveryAddress: Signal<boolean> = this.store$.selectSignal(
    selectOrganizationHasDefaultDeliveryAddress,
  );
  readonly organizationHasMainAddress: Signal<boolean> = this.store$.selectSignal(selectOrganizationHasMainAddress);

  constructor(private readonly store$: Store) {}

  fetchAddresses(searchTerm?: string): void {
    this.store$.dispatch(AddressesActions.fetchAddresses({ searchTerm }));
  }
}
