import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AddressesState, addressesFeatureKey, selectAllAddresses } from './addresses.reducer';

export const selectAddressesState = createFeatureSelector<AddressesState>(addressesFeatureKey);

export const selectAddresses = createSelector(selectAddressesState, (state) => selectAllAddresses(state.addresses));

export const selectAddressesLoaded = createSelector(selectAddressesState, (state) => state.addressesLoaded);

export const selectOrganizationHasDefaultBillingAddress = createSelector(
  selectAddressesState,
  (state) => state.organizationHasDefaultBillingAddress,
);

export const selectOrganizationHasDefaultDeliveryAddress = createSelector(
  selectAddressesState,
  (state) => state.organizationHasDefaultDeliveryAddress,
);

export const selectOrganizationHasMainAddress = createSelector(
  selectAddressesState,
  (state) => state.organizationHasMainAddress,
);
