/**
 * Address types for addresses
 */
export enum AddressType {
  /**
   * Main address for an organization (only one per organization)
   */
  MainAddress = 'MainAddress',
  /**
   * Default delivery address for an organization (only one per organization)
   */
  DefaultDeliveryAddress = 'DefaultDeliveryAddress',
  /**
   * Delivery address
   */
  DeliveryAddress = 'DeliveryAddress',
  /**
   * Default billing address for an organization (only one per organization)
   */
  DefaultBillingAddress = 'DefaultBillingAddress',
  /**
   * Billing address
   */
  BillingAddress = 'BillingAddress',
}

export const addressTypeDescriptions: Record<AddressType, string> = {
  [AddressType.BillingAddress]: 'Rechnungsadresse',
  [AddressType.DefaultBillingAddress]: 'Rechnungsadresse (Standard)',
  [AddressType.DeliveryAddress]: 'Lieferadresse',
  [AddressType.DefaultDeliveryAddress]: 'Lieferadresse (Standard)',
  [AddressType.MainAddress]: 'Hauptadresse',
};
